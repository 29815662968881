@import url("https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  display: flex;
  align-content: center;
  justify-content: center;
  background: #fff;
  font-size: 10px;
  font-family: "Open Sans";
  box-sizing: border-box;
  /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  background-image: linear-gradient(135deg, #FFCDB2 0%, #c3cfe2 100%);
}

.App {
  height: 100vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: black;
}

.ScrollUp {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: black;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 18px;
  line-height: 48px;
  width: 48px;
}

/* Header */

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  top: 5rem;
  position: relative;
}
.headerContainer h1 {
  font-size: 1rem;
}
.headerContainer ul {
  list-style: none;
}
.headerContainer ul li {
  display: inline-block;
  padding-right: 2rem;
}
.headerContainer ul li h2 {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

/* Home */
.homeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 12rem;
}

.homeContainer .homeHeaderWrapper {
  position: relative;
  top: 2rem;
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.homeContainer .homeHeaderWrapper h1 {
  font-size: 4.5rem;
  font-family: "Montserrat";
}
.homeContainer .homeHeaderWrapper h1 span {
  /* color: #1e6fd9; */
  color: #ce796b;
}
.homeContainer .homeHeaderWrapper p {
  position: relative;
  top: -4rem;
  font-size: 2rem;
  opacity: 0.4;
}

.homeContainer .imageContainer img {
  position: relative;
  /* height: 34rem; */
  height: auto;
  width: 28rem;
  top: 5rem;
  border-style: solid;
  border-width: 5px;
  border-top: 0px;
  border-left: 0px;
  margin-left: 10rem;
  /* border-color: #1e6fd9; */
  border-color: #B5838D;
  transition: transform 0.5s ease;
}

.homeContainer .imageContainer img:hover {
  transform: scale(1.1);
}

/* Work */
.workContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 20rem;
  width: 80vw;
}
.workContainer h1 {
  font-size: 5rem;
  font-family: "Montserrat";
}
.workContainer .projectsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.project {
  width: calc(100% - 69%);
}
.workContainer .projectsContainer img {
  height: 30rem;
  width: 100%;
  transition: transform 0.5s ease;
  border-radius: 3rem;
}

.workContainer .projectsContainer img:hover {
  opacity: 0.8;
  transform: scale(1.025);
}

.workContainer .projectsContainer .title {
  font-size: 1.2rem;
}

.git-color {
  color: rgb(67, 67, 182);
}

/* Contact */

.contactContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 20rem;
  padding-bottom: 2rem;
}
.contactContainer h1 {
  font-size: 7rem;
  font-family: "Montserrat";
}
.contactContainer p {
  width: 50vw;
  font-size: 2rem;
  position: relative;
  top: -6rem;
  opacity: 0.4;
}
.contactContainer .email_link {
  font-size: 4rem;
  color: black;
  position: relative;
  word-wrap: break-word;
  top: -4rem;
}

.contactContainer ul {
  list-style-type: none;
}
.contactContainer li {
  display: inline;
  padding-right: 3rem;
}
.contactContainer li a {
  color: black;
  text-decoration: none;
  font-size: 2rem;
}

.social_links li a svg:hover {
  fill: #00B020;
}

/* About */

.spaceAbout {
  height: 20mm;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  width: 80vw;
  justify-content: center;
  position: relative;
  top: 10rem;
}
.aboutContainer h1 {
  font-size: 5rem;
  font-family: "Montserrat";
}
.aboutContainer p {
  position: relative;
  top: -2rem;
  font-size: 1.6rem;
  width: 60vw;
  opacity: 0.4;
}
.aboutContainer .about_contact {
  position: relative;
  top: -20rem;
}

@media only screen and (max-width: 600px) {
  .space {
    height: 20mm;
  }

  .spaceAbout {
    height: 20mm;
  }

  body {
    font-size: 2px;
  }
  /* Header */

  .headerContainer {
    width: 80vw;
    top: 2rem;
  }

  .headerContainer h1 {
    font-size: 1rem;
  }

  .headerContainer ul {
    padding: 0%;
  }

  .headerContainer ul li {
    padding-right: 0.5rem;
  }

  /* Home */

  .homeContainer {
    top: 6rem;
  }

  .homeContainer .homeHeaderWrapper {
    top: 1.5rem;
    width: 60vw;
  }

  .homeContainer .homeHeaderWrapper h1 {
    font-size: 1.2rem;
  }
  .image img {
    width: 100%;
  }
  .homeContainer .homeHeaderWrapper p {
    top: 0rem;
    font-size: 0.7rem;
  }

  .homeContainer .imageContainer img {
    height: 10rem;
    width: 8rem;
    top: 2rem;
    right: 6rem;
  }

  /* Projects */
  .workContainer {
    top: 10rem;
    width: 80vw;
  }

  .workContainer h1 {
    font-size: 2rem;
  }

  .workContainer .projectsContainer {
    grid-gap: 0.5rem;
  }

  .workContainer .projectsContainer img {
    height: 8rem;
  }

  .workContainer .projectsContainer .title {
    font-size: 0.8rem;
  }

  /* Contact */

  .contactContainer {
    top: 12.5rem;
  }

  .contactContainer h1 {
    font-size: 2rem;
  }

  .contactContainer p {
    font-size: 0.62rem;
    top: -1rem;
  }

  .contactContainer .email_link {
    font-size: 1.25rem;
    top: 0rem;
  }

  .contactContainer li {
    padding-right: 1rem;
  }

  .contactContainer li a {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .space {
    height: 20mm;
  }

  .spaceAbout {
    height: 20mm;
  }

  body {
    font-size: 2px;
  }

  /* Header */

  .headerContainer {
    width: 80vw;
    top: 2rem;
  }

  .headerContainer h1 {
    font-size: 1rem;
  }

  .headerContainer ul li {
    padding-right: 1rem;
  }

  /* Home */

  .homeContainer {
    top: 6rem;
  }

  .homeContainer .homeHeaderWrapper {
    top: 1.5rem;
    width: 60vw;
  }

  .homeContainer .homeHeaderWrapper h1 {
    font-size: 1.2rem;
  }

  .homeContainer .homeHeaderWrapper p {
    top: 0rem;
    font-size: 0.7rem;
  }

  .homeContainer .imageContainer img {
    height: 10rem;
    width: 8rem;
    top: 2rem;
  }

  .homeContainer .imageContainer img:hover {
    opacity: 0.5;
  }

  /* Work */
  .workContainer {
    top: 10rem;
    width: 80vw;
  }

  .workContainer h1 {
    font-size: 2rem;
  }

  .workContainer .projectsContainer {
    grid-gap: 0.2rem;
  }

  .workContainer .projectsContainer img {
    height: 8rem;
    width: 8rem;
  }

  .workContainer .projectsContainer img:hover {
    opacity: 0.5;
  }

  .workContainer .projectsContainer .title {
    font-size: 0.8rem;
  }

  /* Contact */

  .contactContainer {
    top: 22.5rem;
  }

  .contactContainer h1 {
    font-size: 2rem;
  }

  .contactContainer p {
    font-size: 0.6rem;
    top: -1rem;
  }

  .contactContainer .email_link {
    font-size: 1rem;
    top: 0rem;
  }

  .contactContainer li {
    padding-right: 1rem;
  }

  .contactContainer li a {
    font-size: 0.5rem;
  }

  .project {
    width: 25%;
    margin: 1rem;
  }
}